/* 
 * *******************************************************************************
 * ES6 IMPORTS
 * The following scripts/libraries are prepended to this file using Codekit
 * *******************************************************************************
 */
//import $ from 'lazysizes';


jQuery(function($) {

	/* 
	 * *******************************************************************************
	 * INIT: FOUNDATION
	 * *******************************************************************************
	 */
	 $(document).foundation();



	/* 
	 * *******************************************************************************
	 * CUSTOM: THEME FUNCTIONS
	 * *******************************************************************************
	 */


	 	/* 
	 	 * STICKY HEADER
	 	 * Makes the header hide when scrolling down and visible on scrolling up
	 	 */
	 	 function cb_sticky_header() {

	 	 	// Get height of header so we can stop the header covering page content
	 	 	var cb_header_height = $('.banner').outerHeight(true);
	 	 	$('body').css('padding-top', cb_header_height);

	 	 	// Add/remove body padding when off-canvas is open/closed
	 	 	// Body padding prevents page elements being hidden by sticky header
	 	 	$('[data-off-canvas]').on('opened.zf.offcanvas', function() {
	 	 		$('body').css('padding-top', '0');
	 	 		$('.banner').fadeOut();
	 	 	});

	 	 	$('[data-off-canvas]').on('closed.zf.offcanvas', function() {
	 	 		cb_header_height = $('.banner').outerHeight(true);
	 	 		$('body').css('padding-top', cb_header_height);
	 	 		$('.banner').delay(1000).fadeIn();
	 	 	});

	 	 	// Show/hide the header on scroll
	 	 	var prev = 0;
	 	 	var $window = $(window);
	 	 	var nav = $('.scrollhide-nav');
	 	 	
	 	 	$window.on('scroll', function(){
	 	 		var scrollTop = $window.scrollTop();

				// fix for Safari which adds negative numbers when scrolling to the top of the page
				if( prev < 0 ) {
					prev = 0;
				}

	 	 		nav.toggleClass('hidden', scrollTop > prev);
	 	 		prev = scrollTop;
	 	 	});

	 	 }


	 	/* REVEAL MODAL */
	 	function cb_menu_modal() {

	 		$('[data-open="modal-menu-item"]').on('click touch', function(event) {

	 			// prevent links from being followed
	 			event.preventDefault();

	 			// If needed, remove content from previous modal
	 			$('.modal-content').empty();

	 			// Add new content
	 			var cb_modal_content = $(this).parents('article').html();
	 			$('.modal-content').append(cb_modal_content);

	 			// Do layout
	 			$('.modal-content .col').addClass('cell small-12 large-6');

	 		});
	 	}

	 	/* PARALLAX */
	 	$(window).scroll(function(){
	 		var scrolled = $(window).scrollTop();
	 		$('.row:first-child .block--banner .block--banner__img').css('bottom', -(scrolled*0.35)+'px');
	 	});


		 /* WAYPOINTS */
		function cb_waypoints() {

		  	// Hide all blocks in preparation for fade in
		  	$('*[id^="block-"]').css('opacity', 0);

		  	// Get all the blocks on this page
		  	var $blocks = $('*[id^="block-"]');

		  	// For each one...
		  	$blocks.each(function(index, block_count) {

		  		// Set up the waypoints
		  		new Waypoint.Inview({

		  			element: this,

		  			// Starts to enter the viewport
		  			enter: function(direction) {

		  				// fade in block
		  				$(this.element).fadeTo('slow', 1, 'swing');

		  				// debug
		  				//console.log(this.element.id + ' enter');
		  			},

		  			// Fully in the viewport
		  			entered: function(direction) {

		  				// show element
		  				$(this.element).css('opacity', '1');

		  				// debug
		  				//console.log(this.element.id + ' entered');
		  			},

		  			// Starts to leave the viewport
		  			exit: function(direction) {

		  				// fade out block
		  				/*if( $(this.element).hasClass('block--banner') ) {
		  					$(this.element).fadeTo('slow', 0, 'swing');
		  				}*/

		  				// debug
		  				//console.log(this.element.id + ' exit');
		  			},

		  			// Fully left the viewport
		  			exited: function(direction) {

		  				// hide element
		  				//$(this.element).fadeTo('slow', 0, 'swing');

		  				// debug
		  				//console.log(this.element.id + ' exited');
		  			}

		  		});

		  	});
		  }		   


		  /* 
		   * WIDOWS
		   */
		   function cb_widows() {
		   		if (Foundation.MediaQuery.atLeast('medium')) {
		   			unorphan($('.block--content p'));
		   		}
		   	}



	/* 
	 * *******************************************************************************
	 * CUSTOM: INIT
	 * *******************************************************************************
	 */
	

		/* DOCUMENT LOAD
		 * Load the following functions on document load
		 */
		 $(document).ready(function() {
		 	cb_sticky_header();
		 	cb_menu_modal();
		 	cb_waypoints();
			cb_widows();
		 });


		/* MEDIA QUERY CHANGE
		 * Watch for when the media query changes and then do somthing
		 */
		 $(window).on('changed.zf.mediaquery', function() {
			cb_widows();
		 });

});